import cookie from 'js-cookie';

export const setCookie = (key, value, remember) => {
  if (process.browser && value) {
    cookie.set(key, value, {
      expires: remember ? 7 : 1,
      path: '/',
      //secure: true
    });
  }
};

export const removeCookie = key => {
  if (process.browser) {
    cookie.remove(key, {
      path: '/',
      //secure: true
    });
  }
};

export const getCookie = (key, req) => {
  return process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req);
};

const getCookieFromBrowser = key => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (!req) return;
  if (!req.headers.cookie) {
    return;
  }
  const rawCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return;
  }
  if (rawCookie.split('=')[1] === 'undefined') {
    return;
  } else {
    return rawCookie.split('=')[1];
  }
};
