import { apiUrls } from '../config';
import fetch from 'isomorphic-unfetch';
import { getCookie } from './session';

import { GetMethod, DeleteMethod, PutMethod, PostMethod, Token } from './api.d';
import { LoginPayload } from '../redux/auth/types.d';
import { Sale, StudentInfo } from '../redux/student/types';
import { Options } from '../redux/types';

function makeHeaders(token?: string) {
  let headers = {};
  if (token) {
    return (headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
  } else {
    if (typeof getCookie('id_token') === 'undefined') {
    } else {
      return (headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('id_token')}`,
      });
    }
  }
}

export const parseOptions = (options: any) => {
  let params = '';
  if (options)
    Object.keys(options).map((option, index) => {
      let value = Object.values(options)[index];
      if (value != null || value != undefined) {
        params += `${index !== 0 ? '&' : ''}${option}=${value}`;
      }
    });
  return params;
};

const parseResponse = async (res: Response) => {
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

const parseErrors = async (err: Error) => {
  if (err) return err;
};

class Api {
  login = async (userInfo: LoginPayload) => {
    return await fetch(apiUrls.login, {
      method: 'post',
      body: JSON.stringify(userInfo),
    }).then(parseResponse);
  };

  logout = async (token: string) => {
    return await fetch(apiUrls.logout, {
      method: 'get',
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  forgotPassword = async (payload: { email: string }) => {
    return await fetch(apiUrls.forgotPassword, {
      method: 'post',
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  resetPassword = async (payload: { password: string; confirm_password: string; token: Token }) => {
    return await fetch(apiUrls.resetPassword, {
      method: 'post',
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };

  getCities = async (state: string, token?: string) => {
    return await fetch(`${apiUrls.cities}?${state}`, {
      method: 'get',
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  getCitiesAutoFill = async (
    options: {
      search: string;
    },
    token?: string
  ) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.cities}?${params}`, {
      method: 'get',
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getCountriesAutoFill = async (options: { search: string }, token: string) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.countries}?${params}`, {
      method: 'GET',
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  getCep = async (options: string, token: string) => {
    return await fetch(`${apiUrls.zipCode}/${options}`, {
      method: 'GET',
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  verificarToken = async (token: string) => {
    return await fetch(apiUrls.verificar + '/' + token, {
      method: 'GET',
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchStudent = async (token?: Token) => {
    return await fetch(`${apiUrls.fetchStudent}`, {
      method: 'get',
      headers: makeHeaders(token),
    } as GetMethod)
      .then(parseResponse)
      .catch(parseErrors);
  };

  getCompanyInfo = async (url?: string) => {
    return await fetch(apiUrls.companyInfo + '/' + url, {
      method: 'GET',
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchCards = async (tab: number, token: string) => {
    return await fetch(`${apiUrls.fetchCards}/${tab}`, {
      method: 'get',
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  paymentMethod = async (processId: number, token: string) => {
    return await fetch(`${apiUrls.paymentMethod}/${processId}?select=true`, {
      method: 'get',
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  changeInstallments = async (processId: number, methodPaymentId: number, token: string) => {
    return await fetch(`${apiUrls.chengeInstallments}/${processId}/${methodPaymentId}`, {
      method: 'get',
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  IncreaseDiscount = async (
    codigoProcesso: number,
    codigoFormaPagamento: number,
    qtdParcelas: number,
    token: string
  ) => {
    return await fetch(
      `${apiUrls.paymentMethod}/${codigoProcesso}/${codigoFormaPagamento}/${qtdParcelas}`,
      {
        method: 'get',
        headers: makeHeaders(token),
      }
    )
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertSale = async (payload: Sale, token: string) => {
    return await fetch(`${apiUrls.insertSale}`, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  updateStudent = async (payload: StudentInfo, token: string) => {
    return await fetch(`${apiUrls.student}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  getFinancialList = async (token: string) => {
    return await fetch(`${apiUrls.getFinancialList}?all=true`, {
      method: 'GET',
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  getFilesList = async (token: string) => {
    return await fetch(`${apiUrls.getFilesList}?all=true`, {
      method: 'GET',
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  insertFile = async (payload: any, token: string) => {
    return await fetch(`${apiUrls.insertFile}`, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  viewFile = async (id: number, token: string) => {
    return await fetch(`${apiUrls.viewFile}/${id}`, {
      method: 'get',
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteFile = async (id: number, token: string) => {
    return await fetch(`${apiUrls.deleteFile}/${id}`, {
      method: 'DELETE',
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchCPF = async (options: Options, token: string) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.student}?${params}`, {
      method: 'GET',
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  fetchAluno = async (alunoId: number, token: string) => {
    return await fetch(`${apiUrls.student}/${alunoId}`, {
      method: 'GET',
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  //Agenda Prática
  fetchStudentSchedule = async (options: any, token: string) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.fetchStudentSchedule}?${params}`, {
      method: 'GET',
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  printSchedule = async (id: string, gerarAgenda: any, token: string) => {
    return await fetch(`${apiUrls.printSchedule}/${id}`, {
      method: 'POST',
      body: JSON.stringify(gerarAgenda),
      headers: makeHeaders(token),
    }).then(parseResponse);
  };

  getVeiculosInstrutores = async (options: any, token: string) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.getVeiculosInstrutores}?${params}`, {
      method: 'GET',
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  insertClass = async (payload: any, token: string) => {
    return await fetch(apiUrls.insertClass, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: makeHeaders(token),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };

  deleteClass = async (payload: any) => {
    return await fetch(`${apiUrls.deleteClass}/${payload.codigo}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    })
      .then(parseResponse)
      .catch(parseErrors);
  };
}

export default new Api();
