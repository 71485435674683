import { Sale, StudentInfo } from './types';

const actions = {
  FETCH_STUDENT_REQUEST: 'FETCH_STUDENT_REQUEST',
  FETCH_STUDENT_SUCCESS: 'FETCH_STUDENT_SUCCESS',
  INSERT_SALE_REQUEST: 'INSERT_SALE_REQUEST',
  INSERT_SALE_SUCCESS: 'INSERT_SALE_SUCCESS',
  FINANCIAL_BUDGET_REQUEST: 'FINANCIAL_BUDGET_REQUEST',
  FINANCIAL_BUDGET_SUCCESS: 'FINANCIAL_BUDGET_SUCCESS',
  FETCH_FINANCIAL_LIST_REQUEST: 'FETCH_FINANCIAL_LIST_REQUEST',
  FETCH_FINANCIAL_LIST_SUCCESS: 'FETCH_FINANCIAL_LIST_SUCCESS',
  FETCH_FILES_LIST_REQUEST: 'FETCH_FILES_LIST_REQUEST',
  FETCH_FILES_LIST_SUCCESS: 'FETCH_FILES_LIST_SUCCESS',
  UPDATE_STUDENT_REQUEST: 'UPDATE_STUDENT_REQUEST',
  UPDATE_STUDENT_SUCCESS: 'UPDATE_STUDENT_SUCCESS',

  fetchStudent: () => ({
    type: actions.FETCH_STUDENT_REQUEST,
  }),

  insertSale: (payload: Sale) => ({
    type: actions.INSERT_SALE_REQUEST,
    payload,
  }),

  updateAluno: (payload: StudentInfo) => ({
    type: actions.UPDATE_STUDENT_REQUEST,
    payload,
  }),

  fetchFinancialList: () => ({
    type: actions.FETCH_FINANCIAL_LIST_REQUEST,
  }),

  fetchFilesList: () => ({
    type: actions.FETCH_FILES_LIST_REQUEST,
  }),
};

export default actions;
